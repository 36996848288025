import classNames from 'classnames'
import React from 'react'
import {
  //A11y,
  Autoplay,
  //Keyboard,
  //Navigation,
  //Pagination,
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import type { Component } from 'constants/types'
import styles from './Carousel.module.scss'
import 'swiper/css'
//import 'swiper/css/a11y'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

type Props = {
  children: React.ReactNode[]
} & Component

export default function Carousel({ children, className, ...props }: Props) {
  return (
    <Swiper
      {...props}
      autoplay={{
        delay: 5000,
      }}
      //keyboard={{
      //  enabled: true,
      //}}
      loop
      modules={[
        //A11y,
        Autoplay,
        //Keyboard,
      ]}
      className={classNames('Carousel', styles.this, className)}>
      {children.map((item, index) => (
        <SwiperSlide
          // eslint-disable-next-line react/no-array-index-key
          key={index}>
          {item}
        </SwiperSlide>
      ))}
    </Swiper>
  )
}