import classNames from 'classnames'
import React from 'react'
import type { Component } from 'constants/types'
import styles from './Section.module.scss'

type Props = {
  children: React.ReactNode
  color?: 'yellow' | 'grey' | 'white'
} & Component

export default function Section({ children, className, color = 'grey', ...props }: Props) {
  return (
    <div {...props} className={classNames('Section', styles.this, styles[`this---${color}`], className)}>
      {children}
    </div>
  )
}