import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Heading from 'components/Heading'
import Row from 'components/Row'
import Text from 'components/Text'
import type { TextProps } from 'components/Text'
import styles from './Title.module.scss'

type Props = {
  actions?: React.ReactNode
  align?: 'left' | 'center'
  children: string
  flush?: boolean
  size?: 'large' | 'medium' | 'small' | 'xsmall'
  subtext?: React.ReactNode
} & TextProps

export default function Title({
  actions,
  align = 'center',
  children,
  className,
  flush,
  size = 'medium',
  subtext,
  ...props
}: Props) {
  return (
    <Text
      {...props}
      align={align}
      element="div"
      className={classNames('Title', styles.this, flush && styles['this---flush'], styles[`this---${size}`], className)}>
      <Row
        //flush//={size !== 'large'}
        flush={size === 'large'}
        size="xsmall">
        <Heading
          align={align}
          //flush//={size === 'large'}
          flush={size !== 'large'}
          levelStyle={1}>
          {children}
        </Heading>
      </Row>
      {subtext && (
        <Row flush={!actions}>
          {_.isString(subtext) ? (
            <Text element="p" flush size="large">
              {subtext}
            </Text>
          ) : subtext}
        </Row>
      )}
      {actions && <div>{actions}</div>}
    </Text>
  )
}
