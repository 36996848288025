import classNames from 'classnames'
import React from 'react'
import type { Component } from 'constants/types'
import styles from './CursorScribble.module.scss'

const DISTANCE_MIN = 1
const POINTS: [number, number][] = []
const POINTS_MAX = 25
let x = 0
let y = 0

type Props = Component

export default function CursorScribble({ className }: Props) {
  const svgRef = React.useRef<SVGSVGElement>(null)
  const [polylinePoints, setPolylinePoints] = React.useState('')
  const [viewBox, setViewBox] = React.useState('0 0 0 0')

  function calculateSize() {
    if (!svgRef.current) return
    const { width, height } = svgRef.current.getBoundingClientRect()
    setViewBox(`0 0 ${width} ${height}`)
  }

  React.useEffect(() => {
    calculateSize()
  }, [])

  React.useEffect(() => {
    function setXY(event: MouseEvent) {
      x = event.clientX
      y = event.clientY
    }

    window.addEventListener('mousemove', setXY)
    return () => window.removeEventListener('mousemove', setXY)
  }, [])

  React.useEffect(() => {
    window.addEventListener('resize', calculateSize)
    return () => window.removeEventListener('resize', calculateSize)
  }, [])

  React.useEffect(() => {
    function frame() {
      requestAnimationFrame(frame)

      if (!x || !y) return

      if (!POINTS.length) return POINTS.push([x, y])

      const [px, py] = POINTS[POINTS.length - 1]
      const distance = Math.sqrt(Math.abs(x - px) + Math.abs(y - py))

      if (distance < DISTANCE_MIN) return

      POINTS.push([x, y])

      // eslint-disable-next-line unicorn/no-array-reduce
      setPolylinePoints(POINTS.reduce((accumulator, [pointsX, pointsY]) => `${accumulator} ${pointsX},${pointsY}`, ''))

      if (POINTS.length > POINTS_MAX) POINTS.shift()
    }

    requestAnimationFrame(frame)
  }, [])

  return (
    <svg
      ref={svgRef}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('CursorScribble', styles.this, className)}>
      <polyline points={polylinePoints} className={styles.polyline} />
    </svg>
  )
}