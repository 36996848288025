const HELP = 'https://help.scribbletogether.com'

export default {
  HELP,
  HELP_MUTUAL_SUPPORT: `${HELP}/mutual-support-membership/`,
  HELP_PAYMENT_LINKS_INVOICES: `${HELP}/payment-links/`,
  HELP_SUBSCRIPTION_MANAGEMENT: `${HELP}/managing-your-subscription/`,

  APP_STORE: 'https://apps.apple.com/us/app/scribble-together-whiteboard/id1266088066',

  INSTAGRAM: 'https://instagram.com/scribbletogethr',
  TIKTOK: 'https://tiktok.com/@scribbletogethr',
  TWITTER: 'https://twitter.com/scribbletogethr',
}