import classNames from 'classnames'
import React from 'react'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import styles from './Copy.module.scss'

type Props = {
  children: React.ReactNode
  columns?: number,
  element?: 'div'
} & Component

export default function Copy({ children, className, columns, element = 'div', ...props }: Props) {
  const style = columns ? { columnCount: columns } : {}

  return (
    <Text {...props} element={element} style={style} className={classNames('Copy', styles.this, columns && styles['this---columns'], className)}>
      {children}
    </Text>
  )
}