import classNames from 'classnames'
import React from 'react'
import type { Component } from 'constants/types'
import styles from './Text.module.scss'

export type Props = {
  align?: 'left' | 'center' | 'right'
  append?: React.ReactNode
  block?: boolean
  casing?: 'capitalize' | 'lower' | 'normal' | 'upper'
  children: React.ReactNode
  color?: 'inherit' | 'default' | 'grey' | 'white'
  copy?: boolean
  element?:
    | 'b'
    | 'blockquote'
    | 'button'
    | 'cite'
    | 'div'
    | 'figcaption'
    | 'figure'
    | 'footer'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'header'
    | 'label'
    | 'legend'
    | 'li'
    | 'nav'
    | 'ol'
    | 'p'
    | 'section'
    | 'span'
    | 'sub'
    | 'sup'
    | 'table'
    | 'td'
    | 'th'
    | 'time'
    | 'u'
    | 'ul'
  flush?: boolean
  font?: 'default'
  size?:
    | 'inherit'
    | 'xxlarge'
    | 'xlarge'
    | 'large'
    | 'medium'
    | 'small'
  textStyle?: 'normal' | 'italic'
  truncate?: boolean,
  weight?: 'inherit' | 'bold' | 'semibold' | 'medium' | 'normal'
  wrap?: boolean
} & Component

export default function Text({
  align,
  append,
  block,
  casing,
  children,
  className,
  color,
  copy,
  element = 'span',
  flush,
  size,
  textStyle,
  truncate,
  weight,
  wrap = true,
  ...props
}: Props) {
  const ComponentElement = element

  if (!children) return null

  return (
    <ComponentElement
      {...props}
      className={classNames(
        'Text',
        styles.this,
        align && styles[`this---${align}`],
        block && styles['this---block'],
        casing && styles[`this---${casing}`],
        color && styles[`this---${color}`],
        copy && styles['this---copy'],
        flush && styles['this---flush'],
        size && styles[`this---${size}`],
        textStyle && styles[`this---style---${textStyle}`],
        truncate && styles['this---truncate'],
        weight && styles[`this---weight---${weight}`],
        !wrap && styles['this---no-wrap'],
        className
      )}>
      {children}
      {append && (
        <>
          &nbsp;{append}
        </>
      )}
    </ComponentElement>
  )
}