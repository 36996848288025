import classNames from 'classnames'
import React from 'react'
import Box from 'components/Box'
import Button from 'components/Button'
import Heading from 'components/Heading'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import styles from './SubscriptionBox.module.scss'
import { ReactComponent as CheckmarkSvg } from './images/checkmark.svg'

type Props = {
  buttonProps: {}
  children: React.ReactNode
  features: string[]
  featuresTitle?: string
  price: string
  priceDetail: string
  title: string
} & Component

const COLOR_DEFAULT = 'grey'

export default function SubscriptionBox({
  buttonCopy,
  buttonProps = {
    color: COLOR_DEFAULT,
  },
  children,
  className,
  color = COLOR_DEFAULT,
  features,
  featuresTitle,
  price,
  priceDetail,
  title,
  ...props
}: Props) {
  return (
    <Box {...props} adornment={color} align="center" className={classNames('SubscriptionBox', styles.this, className)}>
      <Row size="xsmall">
        <Heading color="inherit" flush level={3}>
          {title}
        </Heading>
      </Row>
      <Row size="xsmall">
        <Row size="xxxsmall">
          <Text size="xlarge" weight="semibold">
            {price}
          </Text>
        </Row>
        <Text element="p" flush>
          {priceDetail}
        </Text>
      </Row>
      {featuresTitle && (
        <Heading color="inherit" level={4} levelStyle={3}>
          {featuresTitle}
        </Heading>
      )}
      <Row>
        <Text align="left" element="div">
          {features.map((item) => (
            <Row key={item} size="xsmall">
              <MediaObject
                figure={<CheckmarkSvg aria-hidden className={styles['feature--checkmark']} />}
                size="small"
                className={styles.feature}>
                <Text element="p" flush>
                  {item}
                </Text>
              </MediaObject>
            </Row>
          ))}
        </Text>
      </Row>
      <Button {...buttonProps} full size="small" className={styles.button} />
    </Box>
  )
}