import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import type { Component } from 'constants/types'
import styles from './Buttons.module.scss'

type Props = {
  align?: 'left' | 'center' | 'right',
  axis?: 'x' | 'y'
  buttonProps?: {}
  children: React.ReactNode
  element?: 'div' | 'nav'
} & Component

export default function Buttons({
  align,
  axis = 'x',
  buttonProps,
  children,
  className,
  element = 'div',
  ...props
}: Props) {
  const ComponentElement = element

  const wrappedChildren = React.Children.map(children, (child, index) => {
    if (_.isNil(child)) {
      return null
    }

    const childWithProps = React.cloneElement(child, {
      ...buttonProps,
      ...props,
    })

    return (
      <div
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        className={styles.item}>
        {childWithProps}
      </div>
    )
  })

  return (
    <ComponentElement
      className={classNames(
        'Buttons',
        styles.this,
        align && styles[`this---${align}`],
        styles[`this---${axis}`],
        className
      )}>
      {wrappedChildren}
    </ComponentElement>
  )
}