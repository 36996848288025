import classNames from 'classnames'
import React from 'react'
import ReactBalanceText from 'react-balance-text'
import Text, { TextProps } from 'components/Text'
import styles from './Heading.module.scss'

type Props = {
  align?: 'left' | 'center'
  balance?: boolean
  children: React.ReactNode
  flush?: boolean
  level?: 1 | 2 | 3 | 4 | 5
  levelStyle?: 1 | 2 | 3 | 4 | 5
} & TextProps

export default function Heading({ align, balance, children, className, color, flush, level = 2, levelStyle, ...props }: Props) {
  let element = 'h2'

  if (level === 1) {
    element = 'h1'
  } else if (level === 3) {
    element = 'h3'
  } else if (level === 4) {
    element = 'h4'
  }

  let body = children

  if (((level === 1 || level === 2) && align !== 'left') || balance) {
    body = <ReactBalanceText>{children}</ReactBalanceText>
  }

  return (
    <Text
      {...props}
      color={color}
      element={element}
      className={classNames(
        'Heading',
        styles.this,
        align && styles[`this---${align}`],
        color && styles[`this---color---${color}`],
        flush && styles['this---flush'],
        levelStyle ? styles[`this---${levelStyle}`] : styles[`this---${level}`],
        className
      )}>
      {body}
    </Text>
  )
}
