import classNames from 'classnames'
import React from 'react'
import type { Component } from 'constants/types'
import styles from './MediaObject.module.scss'

export type Props = {
  center?: boolean
  children: React.ReactNode
  figure: React.ReactNode
  figurePosition?: 'left' | 'right'
  inline?: boolean
  size?: 'medium' | 'small' | 'xsmall'
  split?: boolean
} & Component

export default function MediaObject({
  center,
  children,
  className,
  figure,
  figurePosition = 'left',
  inline,
  size = 'medium',
  split,
  // react-media adds non-valid prop
  // @ts-ignore
  matches,
  ...props
}: Props) {
  const isFigurePositionLeft = figurePosition === 'left'

  const childrenWrapped = <div className={styles.body}>{children}</div>

  const figureWrapped = <div className={styles.figure}>{figure}</div>

  return (
    <div
      {...props}
      className={classNames(
        'MediaObject',
        styles.this,
        center && styles['this---center'],
        inline && styles['this---inline'],
        styles[`this---${size}`],
        split && styles['this---split'],
        className,
      )}>
      {isFigurePositionLeft ? figureWrapped : childrenWrapped}
      {isFigurePositionLeft ? childrenWrapped : figureWrapped}
    </div>
  )
}