import classNames from 'classnames'
import React from 'react'
import Heading from 'components/Heading'
import Image from 'components/Image'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import styles from './Card.module.scss'

type Props = {
  children: React.ReactNode
  copy: string
  image: string
} & Component

export default function Card({ children, className, copy, image }: Props) {
  return (
    <figure className={classNames('Card', styles.this, className)}>
      <Image src={image} />
      <figcaption className={styles.body}>
        <Heading level={3}>
          {children}
        </Heading>
        <Text color="grey" element="p" flush>
          {copy}
        </Text>
      </figcaption>
    </figure>
  )
}