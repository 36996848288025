import classNames from 'classnames'
import { Link as GatsbyLink } from 'gatsby'
import React from 'react'
import { isEmail, isMobilePhone } from 'validator'
import type { Component } from 'constants/types'
import formatPhone from 'libs/format-phone'

export type Props = {
  children: React.ReactNode
  exact?: boolean
  nav?: boolean
  onClick?: () => void
  target?: '_blank' | '_self'
  to: string,
} & Component

export default function LinkBase({
  children,
  className,
  exact,
  nav,
  target,
  to,
  matches,
  ...props
}: Props) {
  const componentClassNames = classNames('LinkBase', className)
  const emailPrefix = 'mailto:'
  let toIsEmail
  let toIsPhone
  let body = children || to.replace(/^http(s?):\/\//i, '')

  if (to) {
    toIsEmail = isEmail(to)
    toIsPhone = isMobilePhone(to)
  }

  if (
    !to ||
    toIsEmail ||
    toIsPhone ||
    (to.includes && to.includes('http')) ||
    target === '_blank'
  ) {
    let href = to || null

    if (toIsEmail) {
      body = children || to.replace(emailPrefix, '')
      href = `${emailPrefix}${to}`
    }

    if (toIsPhone) {
      body = children || `${formatPhone.display(to)}`
      href = formatPhone.uri(to)
    }

    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        {...props}
        href={href}
        rel={target === '_blank' && to.includes('http') ? 'noreferrer' : undefined}
        target={target || to ? '_blank' : undefined}
        className={componentClassNames}>
        {body}
      </a>
    )
  }

  if (nav) {
    return (
      <GatsbyLink
        {...props}
        to={to}
        getProps={({ isCurrent, isPartiallyCurrent }) => ({
          className: classNames(
            componentClassNames,
            (exact && isCurrent) || (!exact && isPartiallyCurrent) ? 'active' : undefined
          ),
        })}>
        {body}
      </GatsbyLink>
    )
  }

  return (
    <GatsbyLink {...props} to={to} target={target} className={componentClassNames}>
      {body}
    </GatsbyLink>
  )
}