import classNames from 'classnames'
import React from 'react'
import Media from 'react-media'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import Container from 'components/Container'
import Link from 'components/Link'
import Text from 'components/Text'
import paths from 'constants/paths'
import type { Component } from 'constants/types'
import styles from './Header.module.scss'

type Props = Component

export default function Header({ className }: Props) {
  const navs = [
    {
      copy: 'Overview',
      to: '#overview',
      breakpoint: '768px',
    },
    {
      copy: 'Pricing',
      to: '#pricing',
      breakpoint: '768px',
    },
    {
      copy: 'Join a board',
      // TODO
      to: '#',
    },
  ]

  return (
    <Text element="header" weight="medium" className={classNames('Header', styles.this, className)}>
      <Container className={styles.inner}>
        <nav className={styles.nav}>
          <Link to={paths.HOME} className={styles.brand}>
            Scribble Together
          </Link>
          <Buttons>
            {navs.map((item) => (
              <Media key={item.to} query={`(min-width: ${item.breakpoint ?? 0})`}>
                <Button to={item.to} level="text">
                  {item.copy}
                </Button>
              </Media>
            ))}
          </Buttons>
        </nav>
        <Buttons>
          <Button
            // TODO
            to="#"
            level="text"
            size="xsmall">
            Sign in
          </Button>
          <Button
            // TODO
            to="#"
            size="xsmall">
            Sign up
          </Button>
        </Buttons>
      </Container>
    </Text>
  )
}