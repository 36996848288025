import classNames from 'classnames'
import React from 'react'
import Button from 'components/Button'
import Carousel from 'components/Carousel'
import Image from 'components/Image'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import styles from './ScreenshotsCarousel.module.scss'
import diagramImage from './images/diagram@2x.png'
import feedbackImage from './images/feedback@2x.png'
import playImage from './images/play@2x.png'
import researchImage from './images/research@2x.png'
import studyImage from './images/study@2x.png'
import teachImage from './images/teach@2x.png'
import tutorImage from './images/tutor@2x.png'

type Props = Component

export default function ScreenshotsCarousel({ className }: Props) {
  const [activeIndex, setActiveIndex] = React.useState(0)
  const [swiperInstance, setSwiperInstance] = React.useState()

  const data = [
    {
      copy: 'Tutor',
      image: tutorImage,
    },
    {
      copy: 'Teach',
      image: teachImage,
    },
    {
      copy: 'Study',
      image: studyImage,
    },
    {
      copy: 'Diagram',
      image: diagramImage,
    },
    {
      copy: 'Share feedback',
      image: feedbackImage,
    },
    {
      copy: 'Research',
      image: researchImage,
    },
    {
      copy: 'Play',
      image: playImage,
    },
  ]

  return (
    <div className={classNames('ScreenshotsCarousel', styles.this, className)}>
      <Carousel
        className={styles.carousel}
        onActiveIndexChange={(swiper) => setActiveIndex(swiper.activeIndex - 1)}
        onSwiper={(swiper) => setSwiperInstance(swiper)}>
        {data.map((item) => (
          <Image src2x={item.image} key={item.copy} className={styles.image} />
        ))}
      </Carousel>
      <div className={styles.buttons}>
        {data.map((item, index) => {
          const isActive = index === activeIndex

          return (
            <Button color="white" key={item.copy} size="small" className={styles.button} onClick={() => swiperInstance?.slideTo(index + 1)}>
              <Text
                size="xlarge"
                weight={isActive ? 'semibold' : 'medium'}
                wrap={false}>
                {item.copy}
              </Text>
            </Button>
          )
        })}
      </div>
    </div>
  )
}