import classNames from 'classnames'
import React from 'react'
import units from 'libs/units'
import styles from './Shapes.module.scss'
import { ReactComponent as Shapes1Svg } from './images/1.svg'
import { ReactComponent as Shapes2Svg } from './images/2.svg'
import { ReactComponent as Shapes3Svg } from './images/3.svg'
import { ReactComponent as Shapes4Svg } from './images/4.svg'
import { ReactComponent as Shapes5Svg } from './images/5.svg'
import { ReactComponent as Shapes6Svg } from './images/6.svg'
import { ReactComponent as Shapes7Svg } from './images/7.svg'
import { ReactComponent as Shapes8Svg } from './images/8.svg'
import { ReactComponent as Shapes9Svg } from './images/9.svg'
import { ReactComponent as Shapes10Svg } from './images/10.svg'
import { ReactComponent as Shapes11Svg } from './images/11.svg'
import { ReactComponent as Shapes12Svg } from './images/12.svg'
import { ReactComponent as Shapes13Svg } from './images/13.svg'
import { ReactComponent as Shapes14Svg } from './images/14.svg'
import { ReactComponent as Shapes15Svg } from './images/15.svg'
import { ReactComponent as Shapes16Svg } from './images/16.svg'
import { ReactComponent as Shapes17Svg } from './images/17.svg'
import { ReactComponent as Shapes18Svg } from './images/18.svg'
import { ReactComponent as Shapes19Svg } from './images/19.svg'

export default function Shapes() {
  const shapeProps = {
    className: styles.shape,
  }

  return (
    <div aria-hidden className={classNames('Shapes', styles.this)}>
      <Shapes1Svg
        {...shapeProps}
        style={{
          width: units.pxToVw(109),
          height: units.pxToVw(477),
          top: 0,
        }}
      />
      <Shapes2Svg
        {...shapeProps}
        style={{
          width: units.pxToVw(425),
          height: units.pxToVw(268),
          top: 0,
          right: 0,
        }}
        className={classNames(styles.shape, styles['shape---2'])}
      />
      <Shapes3Svg
        {...shapeProps}
        style={{
          width: units.pxToVw(259),
          height: units.pxToVw(2818),
          top: 0,
        }}
        className={classNames(styles.shape, styles['shape---3'])}
      />
      <Shapes4Svg
        {...shapeProps}
        style={{
          width: units.pxToVw(149),
          height: units.pxToVw(3027),
          top: 0,
        }}
        className={classNames(styles.shape, styles['shape---4'])}
      />
      <Shapes5Svg
        {...shapeProps}
        style={{
          width: units.pxToVw(146),
          height: units.pxToVw(706),
          top: 1500,
          //top: units.pxToVw(1500),
          right: 0,
        }}
      />
      <Shapes6Svg
        {...shapeProps}
        style={{
          width: units.pxToVw(107),
          height: units.pxToVw(1398),
          top: 1400,
          //top: units.pxToVw(1400),
          right: 0,
        }}
      />
      <Shapes7Svg
        {...shapeProps}
        style={{
          width: units.pxToVw(52),
          height: units.pxToVw(99),
          top: 2600,
          //top: units.pxToVw(2600),
        }}
      />
      <Shapes8Svg
        {...shapeProps}
        style={{
          width: units.pxToVw(121),
          height: units.pxToVw(323),
          top: 3200,
          //top: units.pxToVw(3200),
          right: 0,
        }}
      />
      <Shapes9Svg
        {...shapeProps}
        style={{
          width: units.pxToVw(54),
          height: units.pxToVw(606),
          top: 3300,
          //top: units.pxToVw(3300),
          right: 0,
        }}
      />
      <Shapes10Svg
        {...shapeProps}
        style={{
          width: units.pxToVw(79),
          height: units.pxToVw(1467),
          top: 3100,
          //top: units.pxToVw(3100),
        }}
      />
      <Shapes11Svg
        {...shapeProps}
        style={{
          width: units.pxToVw(53),
          height: units.pxToVw(889),
          top: 4100,
          //top: units.pxToVw(4100),
        }}
      />
      <Shapes12Svg
        {...shapeProps}
        style={{
          width: units.pxToVw(59),
          height: units.pxToVw(845),
          top: 4800,
          //top: units.pxToVw(4800),
          right: 0,
        }}
      />
      <Shapes13Svg
        {...shapeProps}
        style={{
          width: units.pxToVw(98),
          height: units.pxToVw(920),
          top: 4800,
          //top: units.pxToVw(4800),
          right: 0,
        }}
      />
      <Shapes14Svg
        {...shapeProps}
        style={{
          width: units.pxToVw(100),
          height: units.pxToVw(101),
          top: 9100,
          //top: units.pxToVw(9100),
          left: units.pxToVw(112),
        }}
        className={classNames(styles.shape, styles['shape---14'])}
      />
      <Shapes15Svg
        {...shapeProps}
        style={{
          width: units.pxToVw(93),
          height: units.pxToVw(99),
          top: 9140,
          //top: units.pxToVw(9140),
          left: units.pxToVw(96),
        }}
        className={classNames(styles.shape, styles['shape---15'])}
      />
      <Shapes16Svg
        {...shapeProps}
        style={{
          width: units.pxToVw(152),
          height: units.pxToVw(2988),
          top: 8630,
          right: 0,
        }}
        className={classNames(styles.shape, styles['shape---16'])}
      />
      <Shapes17Svg
        {...shapeProps}
        style={{
          width: units.pxToVw(153),
          height: units.pxToVw(3358),
          top: 8200,
          //top: units.pxToVw(8200),
          right: 0,
        }}
        className={classNames(styles.shape, styles['shape---17'])}
      />
      <Shapes18Svg
        {...shapeProps}
        style={{
          width: units.pxToVw(88),
          height: units.pxToVw(498),
          top: 10_800,
          //top: units.pxToVw(10_800),
        }}
        className={classNames(styles.shape, styles['shape---18'])}
      />
      <Shapes19Svg
        {...shapeProps}
        style={{
          width: units.pxToVw(114),
          height: units.pxToVw(845),
          top: 10_800,
          //top: units.pxToVw(10_800),
        }}
        className={classNames(styles.shape, styles['shape---19'])}
      />
    </div>
  )
}