import classNames from 'classnames'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import _ from 'lodash'
import React from 'react'
import type { Component } from 'constants/types'
import styles from './Image.module.scss'

type Props = {
  alt?: string
  center?: boolean
  full?: boolean
  src?: IGatsbyImageData | string
  src2x?: string
} & Component

export default function Image({
  alt = '',
  center,
  className,
  full,
  src,
  src2x,
  ...props
}: Props) {
  if (!src && !src2x) return null

  const commonProps = {
    alt,
  }

  if (_.isObject(src)) {
    return (
      <GatsbyImage
        {...commonProps}
        image={src}
        className={classNames('Image', className)}
      />
    )
  }

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img
      {...commonProps}
      {...props}
      src={src}
      srcSet={src2x && `${src2x} 2x`}
      className={classNames(
        'Image',
        styles.this,
        center && styles['this---center'],
        full && styles['this---full'],
        className
      )}
      aria-hidden={alt ? null : true}
    />
  )
}