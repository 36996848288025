import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Media from 'react-media'
import TextLoop from 'react-text-loop'
import Button from 'components/Button'
import Card from 'components/Card'
import Carousel from 'components/Carousel'
import Container from 'components/Container'
import Copy from 'components/Copy'
import Heading from 'components/Heading'
import Image from 'components/Image'
import Layout from 'components/Layout'
import Link from 'components/Link'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Section from 'components/Section'
import Text from 'components/Text'
import Title from 'components/Title'
import CursorScribble from 'components/pages/home/CursorScribble'
import InstagramCarousel from 'components/pages/home/InstagramCarousel'
import ScreenshotsCarousel from 'components/pages/home/ScreenshotsCarousel'
import Shapes from 'components/pages/home/Shapes'
import SubscriptionBox from 'components/pages/home/SubscriptionBox'
import breakpoints from 'constants/breakpoints'
import emails from 'constants/emails'
import urls from 'constants/urls'
import styles from './Home.module.scss'
import { ReactComponent as ContentFooterShapesSvg } from './images/content-footer-shapes.svg'
import { ReactComponent as SubscriptionsShapesSvg } from './images/subscriptions-shapes.svg'
import tryImage from './images/try@2x.png'
import { ReactComponent as UnderlineSvg } from './images/underline.svg'
import heroVideo from './videos/hero.mp4'
import crossDeviceImage from './images/features/cross-device@2x.png'
import crossPlatformImage from './images/features/cross-platform@2x.png'
import drawImage from './images/features/draw@2x.png'
import engageImage from './images/features/engage@2x.png'
import freeGuestsImage from './images/features/free-guests@2x.png'
import gridGraphPapersImage from './images/features/grid-graph-papers@2x.png'
import infininteCanvasImage from './images/features/infinite-canvas@2x.png'
import laserPointerImage from './images/features/laser-pointer@2x.png'
import pdfsImage from './images/features/pdfs@2x.png'
import permissionsImage from './images/features/permissions@2x.png'
import shareImage from './images/features/share@2x.png'
import unlimitedWhiteboardsImage from './images/features/unlimited-whiteboards@2x.png'
import bridgerImage from './images/team/bridger@2x.png'
import mayLiImage from './images/team/may-li@2x.png'
import { ReactComponent as StarsSvg } from './images/testimonials/stars.svg'
import { ReactComponent as TwitterSvg } from './images/testimonials/twitter.svg'
import { ReactComponent as TestimonialsShapes1Svg } from './images/testimonials/shapes/1.svg'
import { ReactComponent as TestimonialsShapes2Svg } from './images/testimonials/shapes/2.svg'
import { ReactComponent as TestimonialsShapes3Svg } from './images/testimonials/shapes/3.svg'

export default function Home() {
  const featuresPrimary = [
    {
      title: 'Draw with delight',
      copy: 'If you use pencil and paper or boards in person, Scribble Together is there for when you’re apart. Feel the best of sharing via the web and the best of drawing using iPad and Apple Pencil.',
      image: drawImage,
    },
    {
      title: 'Party with PDFs',
      copy: 'Import PDFs or images into your Scribble boards to mark them up all you want. You can export back out to PDF when you’re done.',
      image: pdfsImage,
    },
    {
      title: 'Engage everyone',
      copy: 'Allow your guests to scribble with the flip of a switch. Invite people to “come to the board”, or have breakout groups work together on separate sheets while you “walk” around the room.',
      image: engageImage,
    },
    {
      title: 'Share seamlessly',
      copy: 'Send guests a link to hop right in. Or read them a temporary 4-digit code so you can control who you gets in one by one. They don’t have to register or remember passwords.',
      image: shareImage,
    },
  ]

  const featuresSecondary = [
    {
      title: 'Grid & graph papers',
      copy: 'Choose paper size, page breaks, and graph, lined, or music staff backgrounds.',
      image: gridGraphPapersImage,
    },
    {
      title: 'Point with laser pointer',
      copy: 'Use the laser pointer to point and underline and circle things alongside discussions.',
      image: laserPointerImage,
    },
  ]

  const featuresTertiary = [
    {
      title: 'Work with people on any platform',
      copy: 'Our web app runs everywhere — Mac, Windows, Chromebook, Android. Our native iOS app is great for iPad and Apple Pencil.',
      image: crossPlatformImage,
    },
    {
      title: 'See your work from anywhere',
      copy: 'Take notes on a mobile device, then access them from your desktop.',
      image: crossDeviceImage,
    },
    {
      title: 'Control who enters if you need to',
      copy: 'As a board owner you can manage who has permission to enter and collaborate with you and others',
      image: permissionsImage,
    },
    {
      title: 'Free for guests',
      copy: 'As a subscriber you can create shared whiteboards. Your guests don’t need to pay or even create an account.',
      image: freeGuestsImage,
    },
    {
      title: 'Unlimited whiteboards',
      copy: 'We encourage you to create as many whiteboards as you want!',
      image: unlimitedWhiteboardsImage,
    },
    {
      title: 'Infinite canvas',
      copy: 'There is always more room for thinking. Scroll down and keep drawing.',
      image: infininteCanvasImage,
    },
  ]

  const testimonials = [
    {
      copy: 'A day does not go by that I do not used this app',
      authorName: 'Thankful Teacher',
      authorMeta: 'Canada',
      platform: 'app-store',
    },
    {
      copy: '…simple and intuitive. Would highly recommend for online tutoring!',
      authorName: 'May-Li Khoe',
      authorMeta: '@mayli',
      platform: 'twitter',
    },
    {
      copy: 'Scribble saved my class! I don’t know what I’d do without it. Thank you for making this!',
      authorName: 'Grateful Grandma',
      authorMeta: 'Email testimonial',
    },
  ]

  const subscriptions = [
    {
      title: 'Free',
      buttonProps: {
        children: 'Get the app',
        to: urls.APP_STORE,
      },
      color: 'grey',
      features: [
        'Solo notebook on iOS',
        'Unlimited whiteboards',
        'Feels great with Apple Pencil',
        'Work with PDFs',
        'Infinite canvas',
      ],
      price: '$0 per year',
      priceDetail: 'personal notebook for iOS, offline only',
    },
    {
      title: 'Member',
      buttonProps: {
        children: 'Start your free trial',
        // TODO
        to: '#',
      },
      color: 'pink',
      featuresTitle: 'Everything in Free plus…',
      features: [
        'Invite guests to collaborate with you',
        'Your guests join you free, with no sign up',
        'Unlimited guests',
        'Create or access your boards anywhere, anytime via web',
        'Access your boards across devices',
      ],
      price: '$4.16 per month',
      priceDetail: '(if paid at 49.99 per year)',
    },
  ]

  const faqsLinkProps = {
    color: 'pink',
  }

  const faqs = [
    {
      title: 'Do you offer discounted memberships in exchange for mutual support?',
      copy: (
        <>
          We are working hard to ensure that Scribble Together is a sustainable project. Thank you to everyone who has purchased a membership! Your $5/month helps us cover our server costs and build new features. If you don’t have a membership, you can purchase one here.
          {' '}
          <Link {...faqsLinkProps} to={urls.HELP_MUTUAL_SUPPORT}>
            (read more)
          </Link>
        </>
      ),
    },
    {
      title: 'How do I invoice my school?',
      copy: (
        <>
          If your school or business handles payments through a billing admin, you can send them a payment link for your account.
          {' '}
          <Link {...faqsLinkProps} to={urls.HELP_PAYMENT_LINKS_INVOICES}>
            Follow these instructions
          </Link>.
        </>
      ),
    },
    {
      title: 'Do students have to pay?',
      copy: 'If a teacher is a member, they can create unlimited whiteboards and invite students as guests even if the student doesn’t have an account. Students only need a membership if they’d like to create their own shared whiteboards.',
    },
    {
      title: 'How do I manage my subscription or get copies of receipts?',
      copy: (
        <>
          You can manage your subscription by going to All Boards → Settings and clicking the link to manage your subscription. Our
          {' '}
          <Link {...faqsLinkProps} to={urls.HELP_SUBSCRIPTION_MANAGEMENT}>
            help article
          </Link>
          {' '}
          has more&nbsp;information.
        </>
      ),
    },
    {
      title: 'Do you have an enterprise tier?',
      copy: (
        <>
          We’re a small team! We can handle enterprise requests on a case-by-case basis. If you’d like to get Scribble Together for a large group, please
          {' '}
          <Link {...faqsLinkProps} to={emails.HELP}>
            get in&nbsp;touch
          </Link>.
        </>
      ),
    },
  ]

  const team = [
    {
      name: 'May-Li Khoe',
      copy: 'interdisciplinary quatricultural . i design write dance dj . make art music tech . lead subvert consult',
      image: mayLiImage,
    },
    {
      name: 'Bridger Maxwell',
      copy: `I make Scribble Together Whiteboard with @mayli. Day job is engineering Origami Studio. Smiling is my${'\u00A0'}favorite.`,
      image: bridgerImage,
    },
  ]

  const collaborators = [
    {
      name: 'Raphael Arar',
      to: 'https://rarar.com',
    },
    {
      name: 'Em Beauprey',
    },
    {
      name: 'Ian Gilman',
      to: 'https://iangilman.com',
    },
    {
      name: 'Dustin Horton',
      to: 'https://dustinhorton.com',
    },
    {
      name: 'Christina Chung',
      to: 'https://chungtech.org',
    },
    {
      name: 'Jess Wang',
      to: 'https://jesswang.art',
    },
  ]

  const shapesProps = {
    'aria-hidden': true,
    className: styles.shapes,
  }

  const overviewId = 'overview'
  const pricingId = 'pricing'

  const verbs = [
    'Draw',
    'Diagram',
    'Write',
    'Solve',
  ]

  return (
    <Layout className="Home">
      <CursorScribble />
      <div className={styles.this}>
        <Shapes />
        <div className={styles.inner}>
          <Row size="xxxxlarge">
            <Row size="large">
              <header className={styles.hero}>
                <Container className={styles['hero--container']}>
                  <Container align="left" flush size="xsmall" className={styles['hero--content']}>
                    <Heading align="inherit" level={1}>
                      <span  className={styles['hero--verb']}>
                        <TextLoop>
                          {verbs.map((item, index) => (
                            <span key={item}>
                              {item}
                              <UnderlineSvg aria-hidden className={classNames(styles['hero--verb--underline'], styles[`hero--verb--underline---${index}`])} />
                            </span>
                          ))}
                        </TextLoop>
                      </span>
                      {' '}
                      with
                      <br />
                      anyone, anywhere.
                    </Heading>
                    <Row>
                      <Copy size="large" className={styles['hero--copy']}>
                        <Text element="p">
                          Ever wish you could draw with people far away? With no fuss getting them set up?
                        </Text>
                        <Text element="p">
                          Welcome to Scribble Together: a simple, fast-to-share online whiteboard.
                        </Text>
                      </Copy>
                    </Row>
                    <Button size="large">Try a whiteboard now</Button>
                  </Container>
                  <div className={styles['hero--video-container']}>
                    <video src={heroVideo} autoPlay loop muted className={styles['hero--video']} />
                  </div>
                </Container>
              </header>
            </Row>

            <Container>
              <div id={overviewId} />
              <Row size="xxlarge">
                <ScreenshotsCarousel />
              </Row>

              <Row size="xxlarge">
                <div className={styles['features-primary']}>
                  {featuresPrimary.map((item, index) => {
                    const isOdd = index % 2

                    const image = (
                      <div className={styles['features-primary--item--image-container']}>
                        <Image src2x={item.image} className={styles['features-primary--item--image']} />
                      </div>
                    )

                    return (
                      <div key={item.title} className={styles['features-primary--item']}>
                        {!isOdd ? image : undefined}
                        <Container flush size="xxxsmall">
                          <Title subtext={item.copy} align="inherit" flush size="large">
                            {item.title}
                          </Title>
                        </Container>
                        {isOdd ? image : undefined}
                      </div>
                    )
                  })}
                </div>
              </Row>

              <Title subtext="We set this up so that you don’t have to worry (something something).">
                We help you help others
              </Title>
              <Row size="large">
                <div className={styles['features-secondary-grid']}>
                  {featuresSecondary.map((item) => (
                    <Card copy={item.copy} image={item.image} key={item.title}>
                      {item.title}
                    </Card>
                  ))}
                </div>
              </Row>
              <Text align="center" className={styles['features-tertiary-grid']}>
                {featuresTertiary.map((item) => (
                  <figure key={item.title}>
                    <Row size="xsmall">
                      <Image src2x={item.image} />
                    </Row>
                    <Heading balance level={3}>
                      {item.title}
                    </Heading>
                    <Text color="grey" element="p" flush>
                      {item.copy}
                    </Text>
                  </figure>
                ))}
              </Text>
            </Container>
          </Row>

          <Row size="xxxlarge">
            <Container>
              <Title subtext="Hear what others have to say about using Scribble Together">
                <span style={{ position: 'relative' }}>
                  <TestimonialsShapes1Svg
                    {...shapesProps}
                    style={{
                      bottom: '100%',
                      left: '100%',
                      transform: 'translate3d(32px, 20px, 0)',
                    }}
                  />
                  <TestimonialsShapes2Svg
                    {...shapesProps}
                    style={{
                      top: '100%',
                      right: '100%',
                      transform: 'translate3d(-120px, 40px, 0)',
                    }}
                  />
                  <TestimonialsShapes3Svg
                    {...shapesProps}
                    style={{
                      top: '100%',
                      left: '100%',
                      transform: 'translate3d(96px, 96px, 0)',
                    }}
                  />
                  Voices from the community
                </span>
              </Title>
            </Container>
            <Carousel
              centeredSlides
              slidesPerView="auto"
              spaceBetween={48}
              className={styles['testimonials-carousel']}>
              {testimonials.map((item) => {
                let figure

                if (item.platform === 'app-store') {
                  figure = (
                    <Text align="right" element="div" size="small">
                      <Row size="xxxsmall">
                        <StarsSvg aria-label="5-of-5 stars" />
                      </Row>
                      <span>App Store Review</span>
                    </Text>
                  )
                }

                if (item.platform === 'twitter') {
                  figure = (
                    <TwitterSvg aria-label="Twitter" />
                  )
                }

                return (
                  <figure key={item.copy} className={styles.testimonial}>
                    <Row size="xsmall">
                      <Text element="blockquote" size="large" className={styles['testimonial--copy']}>
                        “{item.copy}”
                      </Text>
                    </Row>
                    <figcaption className={styles['testimonial--footer']}>
                      <MediaObject figure={figure} figurePosition="right" center>
                        <Row size="xxxsmall">
                          <Text element="div" weight="semibold">
                            {item.authorName}
                          </Text>
                        </Row>
                        <span>
                          {item.authorMeta}
                        </span>
                      </MediaObject>
                    </figcaption>
                  </figure>
                )
              })}
            </Carousel>
          </Row>

          <Row size="xxxlarge">
            <Container>
              <Row size="xxxlarge">
                <Row size="large">
                  <Image src2x={tryImage} center />
                </Row>
                <div id={pricingId} />
                <Container flush size="xsmall">
                  <Title subtext="Join talented groups of all ages across the globe using Scribble Together today!">
                    Try it today
                  </Title>
                </Container>
                <div className={styles.subscriptions}>
                  <SubscriptionsShapesSvg aria-hidden className={styles['subscriptions--shapes']} />
                  <Container flush size="small">
                    <div className={styles['subscriptions-grid']}>
                      {subscriptions.map((item) => (
                        <SubscriptionBox
                          {...item}
                          buttonProps={{
                            ...item.buttonProps,
                            color: item.color,
                          }}
                          key={item.title}
                        />
                      ))}
                    </div>
                  </Container>
                </div>
              </Row>

              <Container flush size="small">
                <Heading align="center" levelStyle={1}>FAQs</Heading>
                {faqs.map((item) => (
                  <Row key={item.title}>
                    <Heading level={3} levelStyle={2}>
                      {item.title}
                    </Heading>
                    <Text element="p" flush size="large">
                      {item.copy}
                    </Text>
                  </Row>
                ))}
              </Container>
            </Container>
          </Row>

          <Row size="xlarge">
            <Section color="grey">
              <Container>
                <Heading align="center" levelStyle={1}>See what’s Scribbling</Heading>
              </Container>
              <InstagramCarousel />
            </Section>
          </Row>

          <Container size="small">
            <Row size="xlarge">
              <Title subtext="We started Scribble Together because we needed it. Bridger wanted to tutor his niece and May-Li was running a design team that was half remote. Both care deeply about education. May-Li had done early work on the Apple Pencil and Bridger had worked on Paper by Fifty-Three, so it was natural for them to join forces on something that elevated drawing.">
                About us
              </Title>
              <Text align="center">
                <Row>
                  <div className={styles['team-grid']}>
                    {team.map((item) => (
                      <div key={item.name}>
                        <Image src2x={item.image} />
                        <Container flush size="xxxxsmall">
                          <Heading level={3}>
                            {item.name}
                          </Heading>
                          <Text element="p" flush>
                            {item.copy}
                          </Text>
                        </Container>
                      </div>
                    ))}
                  </div>
                </Row>
                <Text align="center" element="p">
                  Scribble is a collaborative project made possible with our wonderful collaborators including
                  {' '}
                  <Media query={`(min-width: ${breakpoints.FULL}`}>
                    <br />
                  </Media>
                  {collaborators.map((item, index) => (
                    <React.Fragment key={item.name}>
                      {item.to ? (
                        <Link to={item.to}>
                          <Text element="u">
                            {item.name}
                          </Text>
                        </Link>
                      ) : item.name}
                      {index < _.size(collaborators) - 1 && ', '}
                    </React.Fragment>
                  ))}
                  …
                </Text>
              </Text>
            </Row>

            <footer className={styles['content-footer']}>
              <ContentFooterShapesSvg aria-hidden className={styles['content-footer--shapes']} />
              <Title
                subtext="Give it a try and start scribbling today!"
                actions={(
                  <Button
                    // TODO
                    to="#"
                    size="large">
                    Try a whiteboard now
                  </Button>
                )}
                flush>
                Ready to Scribble?
              </Title>
            </footer>
          </Container>
        </div>
      </div>
    </Layout>
  )
}