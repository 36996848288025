import classNames from 'classnames'
import React from 'react'
import LinkBase from 'components/LinkBase'
import type { LinkBaseProps } from 'components/LinkBase'
import styles from './Link.module.scss'

type Props = {
  children?: React.ReactNode
  color?: 'inherit' | 'pink' | 'black' | 'white'
  exact?: boolean
  href?: string
  nav?: boolean
  to?: string
} & LinkBaseProps

export default function Link({
  className,
  color = 'inherit',
  exact,
  href,
  nav,
  to,
  matches,
  ...props
}: Props) {
  const isLink = href || to
  const Component = isLink ? LinkBase : 'button'

  return (
    <Component
      {...props}
      href={href}
      to={to}
      exact={isLink ? exact : undefined}
      nav={isLink ? nav : undefined}
      className={classNames(
        'Link',
        styles.this,
        styles[`this---${color}`],
        !isLink && styles['this---button'],
        className
      )}
    />
  )
}