import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Container from 'components/Container'
import Link from 'components/Link'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import urls from 'constants/urls'
import styles from './Footer.module.scss'

type Props = Component

export default function Footer({ className }: Props) {
  const [year, setYear] = React.useState(2022)

  React.useEffect(() => {
    setYear(new Date().getFullYear())
  }, [])

  const socials = [
    {
      copy: 'Instagram',
      to: urls.INSTAGRAM,
    },
    {
      copy: 'Twitter',
      to: urls.TWITTER,
    },
    {
      copy: 'TikTok',
      to: urls.TIKTOK,
    },
  ]

  const linkProps = {
    color: 'white',
  }

  return (
    <Text color="white" element="footer" weight="medium" className={classNames('Footer', styles.this, className)}>
      <Container className={styles.inner}>
        <nav className={styles.nav}>
          {socials.map((item, index) => (
            <React.Fragment key={item.to}>
              <Link {...linkProps} to={item.to}>
                {item.copy}
              </Link>
              {index < _.size(socials) - 1 && (
                <span className={styles.divider}>
                  &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;
                </span>
              )}
            </React.Fragment>
          ))}
        </nav>
        <div>
          <Link {...linkProps} to={urls.HELP}>
            Support
          </Link>
          <span className={styles.copyright}>
            &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;
            © Scribble LLC 2017–{year}
          </span>
        </div>
      </Container>
    </Text>
  )
}