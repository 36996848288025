import { parsePhoneNumber } from 'libphonenumber-js'
import type { NumberFormat, PhoneNumber } from 'libphonenumber-js'

function parse(value: string): PhoneNumber {
  // @ts-ignore
  if (!value) return ''

  try {
    const phone = parsePhoneNumber(value, 'US')

    return phone
  } catch (error) {
    console.error(error)

    // @ts-ignore
    return value
  }
}

// (###) ###-####
function format(value: string, formatType: NumberFormat = 'NATIONAL') {
  const phone = parse(value)

  if (!phone) return value

  return phone.format(formatType)
}

// ##########
function digits(value: string): string {
  const phone = parse(value)

  if (!phone) return value

  return phone.nationalNumber
}

// ###-###-####
const display = (value: string): string => format(value).replace('(', '').replace(') ', '-')

// tel:+1##########
const uri = (value: string): string => format(value, 'RFC3966')

export default {
  digits,
  display,
  uri,
}
