import classNames from 'classnames'
import React from 'react'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import styles from './Box.module.scss'

type Props = {
  adornment?: 'pink' | 'grey'
  children: React.ReactNode
} & Component

export default function Box({ adornment, children, className, ...props }: Props) {
  return (
    <Text {...props} element="div" className={classNames('Box', styles.this, adornment && styles[`this---${adornment}`], className)}>
      {children}
    </Text>
  )
}