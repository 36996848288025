import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Carousel from 'components/Carousel'
import Image from 'components/Image'
import type { Component } from 'constants/types'
import styles from './InstagramCarousel.module.scss'

type Props = Component

export default function InstagramCarousel({ children, className, ...props }: Props) {
  const data = useStaticQuery(
    graphql`
      query {
        allFile(
          filter: {
            name: { glob: "*@2x*" },
            relativeDirectory: { regex: "/InstagramCarousel/" }
          }
        ) {
          nodes {
            id
            childImageSharp {
              gatsbyImageData(
                width: 480
                height: 480
                quality: 100
              )
            }
          }
        }
      }
    `
  )

  return (
    <Carousel
      {...props}
      //breakpoints={{
      //  480: {
      //    slidesPerView: 'auto',
      //  },
      //}}
      centeredSlides
      //slidesPerView={1}
      slidesPerView="auto"
      spaceBetween={48}
      aria-hidden
      aria-label="Scribbles"
      className={classNames('InstagramCarousel', styles.this, className)}>
      {data.allFile.nodes.map((item) => (
        <Image src={item.childImageSharp.gatsbyImageData} key={item.id} className={styles.image} />
      ))}
    </Carousel>
  )
}