import classNames from 'classnames'
import React from 'react'
import LinkBase from 'components/LinkBase'
import type { Component } from 'constants/types'
import styles from './Button.module.scss'

export type Props = {
  active?: boolean
  children: React.ReactNode
  color?: 'inherit' | 'pink' | 'white'
  full?: boolean
  level?: 'primary' | 'secondary' | 'text'
  onClick?: () => void
  size?: 'large' | 'medium' | 'small' | 'xsmall'
  target?: '_blank'
  to?: string
  type?: 'button' | 'submit'
} & Component

export default function Button({
  active,
  children,
  className,
  color: propsColor,
  full,
  level = 'primary',
  size = 'medium',
  to,
  type = 'button',
  ...props
}: Props) {
  let color = propsColor

  if (level === 'primary') color = propsColor || 'pink'
  if (level === 'text') color = propsColor || 'inherit'

  const commonProps = {
    ...props,
    className: classNames(
      'Button',
      styles.this,
      styles[`this---${color}`],
      styles[`this---${level}`],
      styles[`this---${size}`],
      active && styles['this---active'],
      full && styles['this---full'],
      className
    )
  }

  if (to) {
    return (
      <LinkBase {...commonProps} to={to}>
        {children}
      </LinkBase>
    )
  }

  return (
    // eslint-disable-next-line react/button-has-type
    <button {...commonProps} type={type}>
      {children}
    </button>
  )
}
