import classNames from 'classnames'
import React from 'react'
import type { Component } from 'constants/types'
import styles from './Container.module.scss'

type Props = {
  align?: 'left' | 'center' | 'right'
  children: React.ReactNode
  flush?: boolean
  full?: boolean
  size?:
    | 'large'
    | 'medium'
    | 'small'
    | 'xsmall'
    | 'xxsmall'
    | 'xxxsmall'
    | 'xxxxsmall'
} & Component

export default function Container({ align = 'center', children, className, flush, full, size = 'medium', ...props }: Props) {
  return (
    <div
      {...props}
      className={classNames(
        'Container',
        styles.this,
        styles[`this---${align}`],
        flush && styles['this---flush'],
        full && styles['this---full'],
        styles[`this---${size}`],
        className
      )}>
      {children}
    </div>
  )
}