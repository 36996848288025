import { useLocation } from '@reach/router'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from 'components/Footer'
import Header from 'components/Header'
import type { Component } from 'constants/types'
import styles from './Layout.module.scss'
import faviconImage from './images/favicon.png'

type Props = {
  children: React.ReactNode
} & Component

export default function Layout({ children, className, ...props }: Props) {
  const location = useLocation()

  React.useEffect(() => {
    _.defer(() => {
      const { hash } = location

      if (!hash) return

      const element = document.querySelector(hash)

      if (!element) return

      element.scrollIntoView({ behavior: 'smooth' })
    })
  }, [location])

  return (
    <div {...props} className={classNames('Layout', styles.this, className)}>
      <Helmet
        defaultTitle="Scribble"
        titleTemplate="%s — Scribble">
        <link rel="icon" href={faviconImage} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header />
      <main className={styles.main}>
        {children}
      </main>
      <Footer />
    </div>
  )
}