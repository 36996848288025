import classNames from 'classnames'
import React from 'react'
import type { Component as ComponentType } from 'constants/types'
import styles from './Row.module.scss'

type Props = {
  children: React.ReactNode
  element?: 'div'
  flush?: boolean
  size?:
    | 'xlarge'
    | 'large'
    | 'medium'
    | 'small'
    | 'xsmall'
    | 'xxsmall'
    | 'xxxsmall'
    | 'xxxxsmall'
} & ComponentType

export default function Row({ children, className, element = 'div', flush, size = 'medium' }: Props) {
  const Component = element

  return (
    <Component
      className={classNames(
        'Row',
        styles.this,
        flush && styles['this---flush'],
        styles[`this---${size}`],
        className
      )}>
      {children}
    </Component>
  )
}